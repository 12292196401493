<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section
        class="image-cover hero-banner py-5"
        style="background: #eff6ff url(assets/img/banner-2.jpg) no-repeat"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-5">
              <div class="card">
                <div class="card-body">
                  <h3 class="card-header-title text-center mb-4">Log In</h3>
                  <div class="alert alert-danger" v-if="has_error">
                    Invalid Email and/or Password
                  </div>
                  <div class="login-form">
                    <form @submit.prevent="login">
                      <div class="form-group">
                        <label>Email</label>
                        <div class="input-with-icon">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Email"
                            v-model="user.email"
                          />
                          <i class="ti-user"></i>
                        </div>
                      </div>

                      <div class="form-group">
                        <label>Password</label>
                        <div class="input-with-icon">
                          <input
                            type="password"
                            class="form-control"
                            placeholder="*******"
                            v-model="user.password"
                          />
                          <i class="ti-unlock"></i>
                        </div>
                      </div>

                      <div class="form-group text-center">
                        <button
                          type="submit"
                          class="btn btn-md btn-theme-light-2 rounded"
                          v-if="!is_loading"
                        >
                          Login
                        </button>
                        <a
                          href="#"
                          @click.prevent=""
                          v-if="is_loading"
                          class="btn btn-md btn-theme-light-2 rounded"
                          ><i class="lni lni-spinner"></i> Logging In</a
                        >
                      </div>
                    </form>
                  </div>
                  <!-- <div class="modal-divider" v-if="!is_loading">
                    <span>Or login via</span>
                  </div>
                  <div class="social-login mb-3" v-if="!is_loading">
                    <social-auth
                      @hasError="has_error = true"
                      @noError="has_error = false"
                      @isLoading="is_loading = true"
                      @doneLoading="is_loading = false"
                      @success="$emit('success')"
                    />
                  </div> -->
                  <div class="text-center" v-if="!is_loading">
                    <p class="mt-4">
                      <router-link
                        :to="{ name: 'forgot-password' }"
                        class="link"
                        >Forgot password?</router-link
                      >
                    </p>
                    <p class="mt-0">
                      <router-link :to="{ name: 'register' }" class="link"
                        >Dont have an account? Register</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";
// import SocialAuth from "./components/Social.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
    // SocialAuth
  },

  beforeMount() {
    if(this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({ name: 'redirect' }).href
    }
  },

  mounted() {
    document.title = 'RealHub Kenya: Join the Leading Property Platform - Log In and Find Your Dream Home Today'
  },

  data() {
    return {
      user: {},
      has_error: false,
      is_loading: false,
    };
  },

  methods: {
    login() {
      this.has_error = false;
      this.is_loading = true;

      this.$axios
        .post("/api/v1/login", this.user)
        .then((response) => {
          this.$auth.setToken(response.data.access_token);
          this.$emit("success");
          window.location.reload(true)
        })
        .catch(() => {
          this.has_error = true;
          this.is_loading = false;
        });
    },
  },
};
</script>
